import check_icon from "../assets/images/circle_check.svg";
import college_logo from "../assets/images/mycollege_cost_logo.svg";
import up_arrow from "../assets/images/up_arrow.svg";
import down_arrow from "../assets/images/down_arrow.svg";
import ReactPlayer from "react-player/youtube";
import charles_photo from "../assets/images/charles_photo.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
type Props = React.HtmlHTMLAttributes<HTMLDivElement> & {
  url: string;
};
export const VideoBox = (props: Props) => {
  const { url, className } = props;
  return (
    <div className={className}>
      <div className={`player-wrapper rounded-lg max-md:hidden`}>
        <ReactPlayer
          url={url}
          width="100%"
          height="100%"
          loop={false}
          controls={true}
          className={"react-player rounded-md"}
        />
      </div>
      <div className={`player-wrapper rounded-lg md:hidden`}>
        <ReactPlayer
          url={url}
          width="100%"
          height={220}
          loop={false}
          controls={true}
          className={"react-player rounded-md"}
        />
      </div>
    </div>
  );
};
type IProps = {
  title: string;
  desc: string;
};
type ItemProps = {
  title: string;
  desc: string;
  src: string;
};
type ClickableProp = IProps & {
  onClick: () => void;
  isChecked: boolean;
};
const RequirementBox = () => {
  const navigate = useNavigate();
  return (
    <div className="font-opensans space-y-3 xl:w-[800px] md:w-[90vw] max-md:border max-md:border-x-0 border-[#DDE0E4] max-md:py-3 px-2">
      <h6 className="text-[#3FC6AD] font-bold md:text-xl text-lg">
        Requirements
      </h6>
      <ul className="space-y-2 font-light">
        <li className="flex space-x-2">
          <img src={check_icon} alt="" />
          <span>
            Want to enroll in a four-year college or university in the Fall of
            2025.
          </span>
        </li>
        <li className="flex space-x-2">
          <img src={check_icon} alt="" />
          <span>Are a resident of the United States.</span>
        </li>
        <li className="flex space-x-2">
          <img src={check_icon} alt="" />
          <span>
            Plan to enroll as a Full-Time Freshman or Transfer Student.
          </span>
        </li>
        <li className="flex space-x-2">
          <img src={check_icon} alt="" />
          <span>
            Will complete and submit your Free Application for Federal Student
            Aid (FAFSA).
          </span>
        </li>
        <li className="flex space-x-2">
          <img src={check_icon} alt="" />
          <span>
            Will be dependent on a parent or guardian for financial support.
          </span>
        </li>
        <li className="flex space-x-2">
          <img src={check_icon} alt="" />
          <span>
            Are open to sharing selected information from your FAFSA on the
            MyCollegeCosts tool.
          </span>
        </li>
        <li className="flex space-x-2">
          <img src={check_icon} alt="" />
          <span>Can complete a brief demographic form.</span>
        </li>
      </ul>
      <div className="flex flex-col items-center md:flex-row max-md:space-y-2 md:space-x-2">
        <h6 className="text-[#2B574F] ">
          Interested in potentially saving thousands of dollars with
          MyCollegeCosts?
        </h6>
        <div>
          <button
            className="bg-[#3FC6AD] text-white font-bold py-2 rounded-md w-[140px]"
            onClick={() => {
              navigate("/what_cost");
            }}
          >
            Sign up now
          </button>
        </div>
      </div>
      <p className="italic font-light ">
        The security of your information is important to us!  MyCollegeCosts.ai
        is a web-based secure browser best used on a mobile device. The
        information provided can only be released by the student to selected
        colleges and universities.
      </p>
    </div>
  );
};
const PresentingAidAward = () => {
  const navigate = useNavigate();
  return (
    <div className="font-opensans space-y-3 xl:w-[800px] md:w-[90vw] max-md:border max-md:border-x-0 border-[#DDE0E4] max-md:py-3 px-2">
      <h6 className="text-[#3FC6AD] font-bold md:text-xl text-lg">
        Steps needed to present your own student aid award
      </h6>
      <ol className="space-y-2 font-light list-decimal ps-4">
        <li>
          You first must submit your Free Application for Federal Student Aid
          (FASFA) after December 1st.
        </li>
        <li>
          You will receive the FAFSA Submission Summary (FSS) from the
          Department of Education (DOE) with your eligibility information and
          Student Aid Index number (SAI).
        </li>
        <li>You go online and register with MyCollegeCosts.ai</li>
        <li>You add information from your FSS.</li>
        <li>
          You will receive an estimated financial aid award for each institution
          you listed.
        </li>
        <li>
          You will receive a set of paragraphs supporting your request for each
          institution listed.
        </li>
        <li>
          Each award recommendation will combine your SAI/EFC and eligible
          Federal and State resources to apply toward each
          institution’s bill.
        </li>
        <li>
          Provide information about your intended major and planned activities
        </li>
        <li>Add parent information</li>
        <li>Submit your completed MyCollegeCosts information Form</li>
      </ol>
      <div className="flex flex-col items-center md:flex-row max-md:space-y-2 md:space-x-2">
        <h6 className="text-[#2B574F] ">
          Interested in potentially saving thousands of dollars with
          MyCollegeCosts?
        </h6>
        <div>
          <button
            className="bg-[#3FC6AD] text-white font-bold py-2 rounded-md w-[140px]"
            onClick={() => {
              navigate("/what_cost");
            }}
          >
            Sign up now
          </button>
        </div>
      </div>
    </div>
  );
};
const AfterCompleting = () => {
  const navigate = useNavigate();
  return (
    <div className="font-opensans space-y-3 xl:w-[800px] md:w-[90vw] max-md:border max-md:border-x-0 border-[#DDE0E4] max-md:py-3 px-2">
      <h6 className="text-[#3FC6AD] font-bold md:text-xl text-lg">
        After you have completed the required steps, MyCollegeCosts allows you
        to:
      </h6>
      <ul className="space-y-2 font-light list-disc ps-4">
        <li>
          Learn what the direct costs of attending the colleges listed on your
          FAFSA will be.
        </li>
        <li>
          See how much estimated financial aid you will need to pay for those
          direct costs.
        </li>
        <li>
          Understand how much of that financial aid is <strong>FREE</strong> and
          how much you will need to pay back or pay out-of-pocket.
        </li>
        <li>
          Review the expected GAP between the aid you are likely to be offered
          and how much you need.
        </li>
        <li>
          Engage in the important process of financial planning for college.
        </li>
        <li>Avoid insurmountable debt related to college enrollment.</li>
        <li>
          Receive this information without having to wait for an admission
          decision.
        </li>
        <li>
          Know that the college you want to attend is a college you can afford
          to attend.
        </li>
      </ul>
      <div className="flex flex-col items-center md:flex-row max-md:space-y-2 md:space-x-2">
        <h6 className="text-[#2B574F] ">
          Interested in potentially saving thousands of dollars with
          MyCollegeCosts?
        </h6>
        <div>
          <button
            className="bg-[#3FC6AD] text-white font-bold py-2 rounded-md w-[140px]"
            onClick={() => {
              navigate("/what_cost");
            }}
          >
            Sign up now
          </button>
        </div>
      </div>
    </div>
  );
};
const TypesAid = () => {
  const navigate = useNavigate();

  return (
    <div className="font-opensans space-y-3 xl:w-[800px] md:w-[90vw] max-md:border max-md:border-x-0 border-[#DDE0E4] max-md:py-3 px-2">
      <h6 className="text-[#3FC6AD] font-bold md:text-xl text-lg">
        Types of aid
      </h6>
      <p className="font-light">
        There are four primary sources of financial aid: Aid provided by the
        federal government, aid provided by the state in which the student
        lives, aid provided by the college or university under consideration,
        and aid provided by other sources such as an employer or non-profit
        organization.
      </p>
      <p className="font-light">
        Regardless of the source of your financial aid, all aid falls into two
        types: Aid you do not need to pay back (scholarships and grants) AND aid
        you must repay, earn. or provide immediately (loans, work study, and
        self-help).
      </p>
      <p className="font-light">
        Most colleges and universities provide payment plans that allow you to
        distribute any money you must pay the college over a period of several
        months. You should check with the colleges you are considering to learn
        more about payment plans!
      </p>
      <p className="font-light">
        While MyCollegeCosts is based upon the amount of aid you will need to
        meet need based upon the direct cost of attendance, you are permitted to
        borrow up to the cost of attendance (minus any financial aid you have
        been awarded).
      </p>
      <div className="flex flex-col items-center md:flex-row max-md:space-y-2 md:space-x-2">
        <h6 className="text-[#2B574F] ">
          Interested in potentially saving thousands of dollars with
          MyCollegeCosts?
        </h6>
        <div>
          <button
            className="bg-[#3FC6AD] text-white font-bold py-2 rounded-md w-[140px]"
            onClick={() => {
              navigate("/what_cost");
            }}
          >
            Sign up now
          </button>
        </div>
      </div>
    </div>
  );
};
const DirectCostsGAP = () => {
  const navigate = useNavigate();

  return (
    <div className="font-opensans space-y-3 xl:w-[800px] md:w-[90vw] max-md:border max-md:border-x-0 border-[#DDE0E4] max-md:py-3 px-2">
      <h6 className="text-[#3FC6AD] font-bold md:text-xl text-lg">
        About Direct Costs and GAP
      </h6>
      <p className="font-light">
        You have probably seen two costs of attending the colleges or
        universities you are considering. One is known as the COST OF ATTENDANCE
        and the second is a subset of cost of attendance known as DIRECT COST.
      </p>
      <p className="font-light">
        While you can borrow money to meet the full cost of attendance, the
        amount of money you will be billed to attend college includes only
        direct costs.
      </p>
      <p className="font-light">
        Any difference between the total amount of financial aid you are awarded
        and the direct cost of attendance is the amount you will be expected to
        pay using your own resources. This difference is known as a GAP.
      </p>
      <p className="font-light">
        Since colleges and universities will publish their Fall 2025 costs at
        different times of the year, the direct costs used by MyCollegeCosts
        represent those most recently reported to the Department of Education.
        Depending upon college policy, these costs may increase (the average
        increase is 3%) or remain the same.
      </p>
      <table className="table border-collapse">
        <thead>
          <tr>
            <th className="bg-[#2B574F] text-white font-semibold border border-[#3FC6AD]">
              Components of cost of Attendance Budgets
            </th>
            <th className="bg-[#2B574F] text-white font-semibold border border-[#3FC6AD]">
              Billable Expense
            </th>
            <th className="bg-[#2B574F] text-white font-semibold border border-[#3FC6AD]">
              Considered a Direct Cost
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="bg-[#2B8B7A] font-semibold text-white border border-[#3FC6AD] indent-2">
              Tuition and Fees
            </td>
            <td className="indent-2 border border-[#3FC6AD] ">Yes</td>
            <td className="indent-2 border border-[#3FC6AD] ">Yes</td>
          </tr>
          <tr>
            <td className="bg-[#2B8B7A] font-semibold text-white border border-[#3FC6AD] indent-2">
              Food and Housing
            </td>
            <td className="indent-2 border border-[#3FC6AD] ">
              Yes<sup>*</sup>
            </td>
            <td className="indent-2 border border-[#3FC6AD] ">
              Yes<sup>*</sup>
            </td>
          </tr>
          <tr>
            <td className="bg-[#2B8B7A] font-semibold text-white border border-[#3FC6AD] indent-2">
              Books, course materials, supplies and equipment
            </td>
            <td className="indent-2 border border-[#3FC6AD] ">
              No<sup>**</sup>
            </td>
            <td className="indent-2 border border-[#3FC6AD] ">
              No<sup>**</sup>
            </td>
          </tr>
          <tr>
            <td className="bg-[#2B8B7A] font-semibold text-white border border-[#3FC6AD] indent-2">
              “Other Expenses”
            </td>
            <td className="indent-2 border border-[#3FC6AD] ">No</td>
            <td className="indent-2 border border-[#3FC6AD] ">No</td>
          </tr>
          <tr>
            <td className="bg-[#2B8B7A] font-semibold text-white border border-[#3FC6AD] indent-2">
              *Direct cost only if living on campus.
            </td>
            <td className="bg-[#2B8B7A] font-semibold text-white border border-[#3FC6AD] indent-2"></td>
            <td className="indent-2 border border-[#3FC6AD] "></td>
          </tr>
          <tr>
            <td
              colSpan={3}
              className="bg-[#2B8B7A] font-semibold text-white border border-[#3FC6AD] indent-2"
            >
              ** Books and supplies are not billable expenses unless they are
              included in a comprehensive tuition charge.
            </td>
          </tr>
        </tbody>
      </table>
      <div className="flex flex-col items-center md:flex-row max-md:space-y-2 md:space-x-2">
        <h6 className="text-[#2B574F] ">
          Interested in potentially saving thousands of dollars with
          MyCollegeCosts?
        </h6>
        <div>
          <button
            className="bg-[#3FC6AD] text-white font-bold py-2 rounded-md w-[140px]"
            onClick={() => {
              navigate("/what_cost");
            }}
          >
            Sign up now
          </button>
        </div>
      </div>
    </div>
  );
};
const MoneyPayback = () => {
  const navigate = useNavigate();

  return (
    <div className="font-opensans space-y-3 xl:w-[800px] md:w-[90vw] max-md:border max-md:border-x-0 border-[#DDE0E4] max-md:py-3 px-2">
      <div className="grid grid-cols-2">
        <div className="space-y-3">
          <h6 className="text-[#3FC6AD] font-bold md:text-xl text-lg">
            Money you will not have to pay back includes:{" "}
          </h6>
          <ul className="space-y-2 font-light">
            <li className="flex space-x-2">
              <img src={check_icon} alt="" />
              <span>Federal Pell Grant</span>
            </li>
            <li className="flex space-x-2">
              <img src={check_icon} alt="" />
              <span>
                Federal Supplemental Educational Opportunity Grant (SEOG)
              </span>
            </li>
            <li className="flex space-x-2">
              <img src={check_icon} alt="" />
              <span>College or University Academic Scholarships</span>
            </li>
            <li className="flex space-x-2">
              <img src={check_icon} alt="" />
              <span>College or University Athletic Aid</span>
            </li>
            <li className="flex space-x-2">
              <img src={check_icon} alt="" />
              <span>College or University Need-Based Aid</span>
            </li>
            <li className="flex space-x-2">
              <img src={check_icon} alt="" />
              <span>College or University Endowed Aid</span>
            </li>
            <li className="flex space-x-2">
              <img src={check_icon} alt="" />
              <span>Other College or University Awards</span>
            </li>
            <li className="flex space-x-2">
              <img src={check_icon} alt="" />
              <span>State Grants</span>
            </li>
            <li className="flex space-x-2">
              <img src={check_icon} alt="" />
              <span>State Scholarships</span>
            </li>
            <li className="flex space-x-2">
              <img src={check_icon} alt="" />
              <span>Outside Scholarships or grants</span>
            </li>
          </ul>
        </div>
        <div className="space-y-3">
          <h6 className="text-[#A93838] font-bold md:text-xl text-lg">
            Money you will have to earn and apply to your bill:
          </h6>
          <ul className="space-y-2 font-light">
            <li>Federal Work Study</li>
            <li>Campus Work Programs</li>
          </ul>
          <h6 className="text-[#A93838] font-bold">Money you must pay back:</h6>
          <ul className="space-y-2 font-light">
            <li>Direct subsidized Loan</li>
            <li>Direct Unsubsidized Loan</li>
            <li>Parent PLUS Loan</li>
            <li>Bank or Credit Union Loan</li>
            <li>Credit Card</li>
          </ul>
        </div>
      </div>

      <div className="flex flex-col items-center md:flex-row max-md:space-y-2 md:space-x-2">
        <h6 className="text-[#2B574F] ">
          Interested in potentially saving thousands of dollars with
          MyCollegeCosts?
        </h6>
        <div>
          <button
            className="bg-[#3FC6AD] text-white font-bold py-2 rounded-md w-[140px]"
            onClick={() => {
              navigate("/what_cost");
            }}
          >
            Sign up now
          </button>
        </div>
      </div>
    </div>
  );
};
const MakingYourCase = () => {
  const navigate = useNavigate();

  return (
    <div className="font-opensans space-y-3 xl:w-[800px] md:w-[90vw] max-md:border max-md:border-x-0 border-[#DDE0E4] max-md:py-3 px-2">
      <h6 className="text-[#3FC6AD] font-bold md:text-xl text-lg">
        Making your case
      </h6>
      <p className="font-light">
        With rare exception, the amount of federal, state and outside aid you
        receive is not negotiable. But remember that a significant percentage of
        financial aid is provided by the college or university you are
        considering. It is possible to request additional aid from that
        institution.  After all, you have nothing to lose and a great deal to
        gain by making such a request!
      </p>
      <p className="font-light">
        How you make such a request has an important impact upon the likelihood
        that your request will be granted. That’s why MyCollegeCosts provides
        you example text to use when you reach out to the Director of Financial
        Aid or your Admissions counselor! We will help you make the best
        possible case to receive enough aid to cover 100% of the direct costs of
        attending that college. If your college is unable to meet your direct
        costs, you have an opportunity to make a similar request of each of the
        colleges you have listed on your FAFSA. 
      </p>
      <p className="font-light">
        By submitting your MyCollegeCosts results to each college you may be
        considering you have an opportunity to actually compare how each will
        meet the direct of attendance AND you will know how much you are likely
        to pay!
      </p>
      <div className="flex flex-col items-center md:flex-row max-md:space-y-2 md:space-x-2">
        <h6 className="text-[#2B574F] ">
          Interested in potentially saving thousands of dollars with
          MyCollegeCosts?
        </h6>
        <div>
          <button
            className="bg-[#3FC6AD] text-white font-bold py-2 rounded-md w-[140px]"
            onClick={() => {
              navigate("/what_cost");
            }}
          >
            Sign up now
          </button>
        </div>
      </div>
    </div>
  );
};
const InformationYouNeedIt = () => {
  const navigate = useNavigate();

  return (
    <div className="font-opensans space-y-3 xl:w-[800px] md:w-[90vw] max-md:border max-md:border-x-0 border-[#DDE0E4] max-md:py-3 px-2">
      <h6 className="text-[#3FC6AD] font-bold md:text-xl text-lg">
        Information when You need it
      </h6>
      <p className="font-light">
        Do you know that no matter when you complete your FAFSA, most colleges
        will NOT provide you a financial aid package until you have been
        accepted for admission? 
      </p>
      <p className="font-light">
        With MyCollegeCosts, you will receive estimated award information that
        meets the direct cost of attending college no matter what your admission
        status may be. This means that you can make important decisions about
        affordability before you complete the application process!
      </p>
      <p className="font-light">
        Knowing how much financial aid you are likely to need allows you to
        begin your financial planning for college before you are faced with
        state or institutional deadlines. Explore the availability of outside
        scholarships and grants. Decide how and when to apply for alternative
        forms of aid. Plan to use summer earnings to meet some of your
        educational expenses. The earlier you know the amount of financial
        assistance you need to attend college, the more time you have to plan
        your financial future.
      </p>
      <div className="flex flex-col items-center md:flex-row max-md:space-y-2 md:space-x-2">
        <h6 className="text-[#2B574F] ">
          Interested in potentially saving thousands of dollars with
          MyCollegeCosts?
        </h6>
        <div>
          <button
            className="bg-[#3FC6AD] text-white font-bold py-2 rounded-md w-[140px]"
            onClick={() => {
              navigate("/what_cost");
            }}
          >
            Sign up now
          </button>
        </div>
      </div>
    </div>
  );
};
const SeeResults = () => {
  const navigate = useNavigate();

  return (
    <div className="font-opensans space-y-3 xl:w-[800px] md:w-[90vw] max-md:border max-md:border-x-0 border-[#DDE0E4] max-md:py-3 px-2">
      <h6 className="text-[#3FC6AD] font-bold md:text-xl text-lg">
        See results
      </h6>
      <p className="font-light">
        Unlike many financial aid estimators based upon average aid awarded,
        MyCollegeCosts will provide you financial aid information that is unique
        to your talents, circumstances, and needs.
      </p>
      <p className="font-light">
        Once you have completed the MyCollegeCosts application, a financial aid
        information sheet will be developed and shared with you, your
        parents/guardians, and any college you have identified as a recipient. 
      </p>
      <p className="font-light">
        More importantly, we will tell you how much money you will be expected
        to pay to attend the colleges you are considering
      </p>
      <div className="flex flex-col items-center md:flex-row max-md:space-y-2 md:space-x-2">
        <h6 className="text-[#2B574F] ">
          Interested in potentially saving thousands of dollars with
          MyCollegeCosts?
        </h6>
        <div>
          <button
            className="bg-[#3FC6AD] text-white font-bold py-2 rounded-md w-[140px]"
            onClick={() => {
              navigate("/what_cost");
            }}
          >
            Sign up now
          </button>
        </div>
      </div>
    </div>
  );
};
export const CustomTab = ({
  title,
  desc,
  onClick,
  isChecked,
}: ClickableProp) => {
  return (
    <div
      className={`xl:w-[400px] md:w-[360px] px-2 py-2 space-y-4 ${
        isChecked
          ? "border-0 border-t-2 border-[#2B8B7A]"
          : "border-0 border-t-2 border-[#3FC6AD] border-opacity-20"
      }`}
    >
      <div
        className="flex justify-between cursor-pointer"
        onClick={() => {
          onClick();
        }}
      >
        <h6
          className={
            isChecked
              ? "font-bold text-[#2B8B7A] text-xl"
              : "text-xl text-[#646464]"
          }
        >
          {title}
        </h6>
        {isChecked ? (
          <img src={up_arrow} alt="" />
        ) : (
          <img src={down_arrow} alt="" />
        )}
      </div>
      <p
        className=" font-light text-[#646464] text-lg cursor-pointer"
        onClick={() => {
          onClick();
        }}
      >
        {desc}
      </p>
    </div>
  );
};
export const StudentParents = () => {
  const [selectTabX1, setSelectTabX1] = useState(1);
  const [selectTabX2, setSelectTabX2] = useState(0);
  const [selectTabX3, setSelectTabX3] = useState(0);
  const [selectTabM1, setSelectTabM1] = useState(0);
  const [selectTabM2, setSelectTabM2] = useState(0);
  const [selectTabM3, setSelectTabM3] = useState(0);
  const [selectTabM4, setSelectTabM4] = useState(0);
  const [selectTabM5, setSelectTabM5] = useState(0);
  const [selectTabSm, setSelectTabSm] = useState(0);
  return (
    <>
      <div className="max-md:hidden grid gap-y-[40px] font-work_sans pb-[40px]">
        <section className="student_parent_hero_bg w-100 md:h-[360px] xl:h-[500px] flex items-center justify-center relative">
          <div className="flex justify-center p-8 space-x-4 space-y-2 md:hero_md_caption xl:hero_caption">
            <div className="space-y-3">
              <div className="flex items-center space-x-4">
                <div>
                  <img src={college_logo} alt="" />
                </div>
                <h6 className="text-xl font-extrabold text-white">
                  MyCollegeCosts
                </h6>
              </div>
              <h6 className="text-[#fff] w-[550px] text-center font-poppins font-normal">
                A tool to help students receive the best possible financial aid
                award
              </h6>
            </div>
            {/* <div>
              <button className="text-white bg-[#3FC6AD] font-extrabold px-8 py-3 rounded-lg">
                Register Now
              </button>
            </div> */}
          </div>
        </section>
        <section className="flex xl:justify-center xl:flex-row md:flex-col-reverse xl:space-x-[75px] md:space-x-3 md:px-3">
          <div className="border-shadow md:p-3 xl:p-9 md:w-[90vw] xl:w-[783px] space-y-2">
            <h5 className="text-[#2B8B7A] text-lg font-bold font-opensans">
              Students and Parents
            </h5>
            <p className="text-[#646464] font-light">
              You start the financial aid conversation with the colleges of your
              choice.
            </p>
            <p className="text-[#646464] font-light">
              You tell the schools what you can afford and what it will take for
              you to enroll.
            </p>
            <p className="text-[#646464] font-light">
              You request a quick response and, thus, have plenty of time to...
            </p>
            <ul className="font-opensans font-light text-[#646464] space-y-3">
              <li className="flex space-x-2">
                <img src={check_icon} alt="" />
                <span>Decide to attend or not.</span>
              </li>
              <li className="flex space-x-2">
                <img src={check_icon} alt="" />
                <span>Choose another school if necessary.</span>
              </li>
              <li className="flex space-x-2">
                <img src={check_icon} alt="" />
                <span>More effectively plan how to pay.</span>
              </li>
            </ul>
            <p className="font-light font-opensans text-[#646464] pt-2">
              MyCollegeCosts gives you the detailed data you need to determine
              what you can really afford AND provides you with sample letters
              and emails you can send to the colleges of your choice to ensure
              that you get the dollars you need!
            </p>
          </div>
          <div className="flex items-center justify-center md:pb-5 xl:pb-0">
            <VideoBox
              url="https://youtu.be/6l5ejHwEJNc"
              className="md:w-[426px] xl:w-[547px] md:h-[40vw] xl:h-[350px] rounded-lg"
            />
          </div>
        </section>
        <section>
          <div className="flex justify-between xl:w-[1440px] mx-auto max-xl:hidden">
            <CustomTab
              title="Requirements"
              desc="Requirements needed to use MyCollegeCosts."
              onClick={() => {
                if (selectTabX1 === 1) setSelectTabX1(0);
                else setSelectTabX1(1);
              }}
              isChecked={selectTabX1 === 1}
            />
            <CustomTab
              title="Presenting student aid award"
              desc="Steps needed to present your own student aid award."
              onClick={() => {
                if (selectTabX1 === 2) setSelectTabX1(0);
                else setSelectTabX1(2);
              }}
              isChecked={selectTabX1 === 2}
            />
            <CustomTab
              title="After completing steps"
              desc="What to do after completing your required steps."
              onClick={() => {
                if (selectTabX1 === 3) setSelectTabX1(0);
                else setSelectTabX1(3);
              }}
              isChecked={selectTabX1 === 3}
            />
          </div>
          {selectTabX1 === 0 ? null : (
            <div className="xl:w-[1440px] mx-auto border border-x-0 border-y-1 border-[#DDE0E4] py-4 mt-4 max-xl:hidden">
              {selectTabX1 === 1 ? <RequirementBox /> : null}
              {selectTabX1 === 2 ? <PresentingAidAward /> : null}
              {selectTabX1 === 3 ? <AfterCompleting /> : null}
            </div>
          )}

          <div className="flex justify-between xl:w-[1440px] mx-auto max-xl:hidden mt-8">
            <CustomTab
              title="About Direct Costs and GAP"
              desc="Learn more about Direct Costs and GAP regarding you."
              onClick={() => {
                if (selectTabX2 === 1) setSelectTabX2(0);
                else setSelectTabX2(1);
              }}
              isChecked={selectTabX2 === 1}
            />
            <CustomTab
              title="Types of aid"
              desc="Learn more about types of aid and how they differ."
              onClick={() => {
                if (selectTabX2 === 2) setSelectTabX2(0);
                else setSelectTabX2(2);
              }}
              isChecked={selectTabX2 === 2}
            />
            <CustomTab
              title="Money you will not pay back "
              desc="Learn about money you will not have to pay back in regards to your aid. "
              onClick={() => {
                if (selectTabX2 === 3) setSelectTabX2(0);
                else setSelectTabX2(3);
              }}
              isChecked={selectTabX2 === 3}
            />
          </div>
          {selectTabX2 === 0 ? null : (
            <div className="xl:w-[1440px] mx-auto border border-x-0 border-y-1 border-[#DDE0E4] py-4 mt-4 max-xl:hidden">
              {selectTabX2 === 1 ? <DirectCostsGAP /> : null}
              {selectTabX2 === 2 ? <TypesAid /> : null}
              {selectTabX2 === 3 ? <MoneyPayback /> : null}
            </div>
          )}

          <div className="flex justify-between xl:w-[1440px] mx-auto max-xl:hidden mt-8">
            <CustomTab
              title="Information when You need it"
              desc="Learn more about information that you will need."
              onClick={() => {
                if (selectTabX3 === 1) setSelectTabX3(0);
                else setSelectTabX3(1);
              }}
              isChecked={selectTabX3 === 1}
            />
            <CustomTab
              title="Making your case"
              desc="Learn more about making your case when it comes to negotiation."
              onClick={() => {
                if (selectTabX3 === 2) setSelectTabX3(0);
                else setSelectTabX3(2);
              }}
              isChecked={selectTabX3 === 2}
            />
            <CustomTab
              title="See results"
              desc="Learn more about the results and information that is unique to you."
              onClick={() => {
                if (selectTabX3 === 3) setSelectTabX3(0);
                else setSelectTabX3(3);
              }}
              isChecked={selectTabX3 === 3}
            />
          </div>
          {selectTabX3 === 0 ? null : (
            <div className="xl:w-[1440px] mx-auto border border-x-0 border-y-1 border-[#DDE0E4] py-4 mt-4 max-xl:hidden">
              {selectTabX3 === 1 ? <InformationYouNeedIt /> : null}
              {selectTabX3 === 2 ? <MakingYourCase /> : null}
              {selectTabX3 === 3 ? <SeeResults /> : null}
            </div>
          )}

          <div className="max-md:hidden md:flex justify-between xl:hidden md:w-[90vw] mx-auto space-x-3">
            <CustomTab
              title="Requirements"
              desc="Requirements needed to use MyCollegeCosts."
              onClick={() => {
                if (selectTabM1 === 1) setSelectTabM1(0);
                else setSelectTabM1(1);
              }}
              isChecked={selectTabM1 === 1}
            />
            <CustomTab
              title="Presenting student aid award"
              desc="Steps needed to present your own student aid award."
              onClick={() => {
                if (selectTabM1 === 2) setSelectTabM1(0);
                else setSelectTabM1(2);
              }}
              isChecked={selectTabM1 === 2}
            />
          </div>
          {selectTabM1 === 0 ? null : (
            <div className="md:w-[90vw] mx-auto border border-x-0 border-y-1 border-[#DDE0E4] py-4 mt-4 xl:hidden max-md:hidden">
              {selectTabM1 === 1 ? <RequirementBox /> : null}
              {selectTabM1 === 2 ? <PresentingAidAward /> : null}
            </div>
          )}

          <div className="max-md:hidden md:flex justify-between xl:hidden md:w-[90vw] mx-auto space-x-3 mt-6">
            <CustomTab
              title="After completing steps"
              desc="What to do after completing your required steps."
              onClick={() => {
                if (selectTabM2 === 1) setSelectTabM2(0);
                else setSelectTabM2(1);
              }}
              isChecked={selectTabM2 === 1}
            />
            <CustomTab
              title="About Direct Costs and GAP"
              desc="Learn more about Direct Costs and GAP regarding you."
              onClick={() => {
                if (selectTabM2 === 2) setSelectTabM2(0);
                else setSelectTabM2(2);
              }}
              isChecked={selectTabM2 === 2}
            />
          </div>
          {selectTabM2 === 0 ? null : (
            <div className="md:w-[90vw] mx-auto border border-x-0 border-y-1 border-[#DDE0E4] py-4 mt-4 xl:hidden max-md:hidden">
              {selectTabM2 === 1 ? <AfterCompleting /> : null}
              {selectTabM2 === 2 ? <DirectCostsGAP /> : null}
            </div>
          )}

          <div className="max-md:hidden md:flex justify-between xl:hidden md:w-[90vw] mx-auto space-x-3 mt-6">
            <CustomTab
              title="Types of aid"
              desc="Learn more about types of aid and how they differ."
              onClick={() => {
                if (selectTabM3 === 1) setSelectTabM3(0);
                else setSelectTabM3(1);
              }}
              isChecked={selectTabM3 === 1}
            />
            <CustomTab
              title="Money you will not pay back "
              desc="Learn about money you will not have to pay back in regards to your aid. "
              onClick={() => {
                if (selectTabM3 === 2) setSelectTabM3(0);
                else setSelectTabM3(2);
              }}
              isChecked={selectTabM3 === 2}
            />
          </div>
          {selectTabM3 === 0 ? null : (
            <div className="md:w-[90vw] mx-auto border border-x-0 border-y-1 border-[#DDE0E4] py-4 mt-4 xl:hidden max-md:hidden">
              {selectTabM3 === 1 ? <TypesAid /> : null}
              {selectTabM3 === 2 ? <MoneyPayback /> : null}
            </div>
          )}

          <div className="max-md:hidden md:flex justify-between xl:hidden md:w-[90vw] mx-auto space-x-3 mt-6">
            <CustomTab
              title="Information when You need it"
              desc="Learn more about information that you will need."
              onClick={() => {
                if (selectTabM4 === 1) setSelectTabM4(0);
                else setSelectTabM4(1);
              }}
              isChecked={selectTabM4 === 1}
            />
            <CustomTab
              title="Making your case"
              desc="Learn more about making your case when it comes to negotiation."
              onClick={() => {
                if (selectTabM4 === 2) setSelectTabM4(0);
                else setSelectTabM4(2);
              }}
              isChecked={selectTabM4 === 2}
            />
          </div>
          {selectTabM4 === 0 ? null : (
            <div className="md:w-[90vw] mx-auto border border-x-0 border-y-1 border-[#DDE0E4] py-4 mt-4 xl:hidden max-md:hidden">
              {selectTabM4 === 1 ? <InformationYouNeedIt /> : null}
              {selectTabM4 === 2 ? <MakingYourCase /> : null}
            </div>
          )}

          <div className="max-md:hidden md:flex justify-between xl:hidden md:w-[90vw] mx-auto space-x-3 mt-6">
            <CustomTab
              title="See results"
              desc="Learn more about the results and information that is unique to you."
              onClick={() => {
                if (selectTabM5 === 1) setSelectTabM5(0);
                else setSelectTabM5(1);
              }}
              isChecked={selectTabM5 === 1}
            />
          </div>
          {selectTabM5 === 0 ? null : (
            <div className="md:w-[90vw] mx-auto border border-x-0 border-y-1 border-[#DDE0E4] py-4 mt-4 xl:hidden max-md:hidden">
              <SeeResults />
            </div>
          )}
        </section>
        <section>
          <div className="md:w-[85vw] xl:w-[916px] p-8 mx-auto border border-[#3FC6AD] rounded-lg space-y-2 font-opensans">
            <div>
              <img src={charles_photo} alt="" className="mx-auto" />
            </div>
            <h6 className="text-[#3FC6AD] font-bold text-center">
              College President
            </h6>
            <h6 className="xl:text-xl text-[#2B574F] font-semibold text-center">
              Mr. Charles A. (Tony) McGeorge
            </h6>
            <p className="font-light text-[#2B574F] text-center">
              As the headmaster of three independent secondary schools, I have witnessed how the escalating 
              cost of a college education is making it increasingly unaffordable for many families. 
              The rising cost of college and the looming burden of student debt are causing undue financial stress 
              and anxiety for students and their families. My commitment to making college affordable for all students 
              and MyCollegeCosts' ability to maximize financial aid awards have motivated me to join their team.
            </p>
          </div>
        </section>
      </div>
      <div className="grid px-4 py-4 md:hidden gap-y-4 font-work_sans">
        <section className="flex xl:justify-center flex-col xl:space-x-[75px] space-y-4 md:px-3">
          <div className="flex items-center justify-center md:pb-5 xl:pb-0">
            <VideoBox
              url="https://youtu.be/6l5ejHwEJNc"
              className="md:w-[426px] xl:w-[547px] md:h-[40vw] xl:h-[350px] rounded-lg"
            />
          </div>
          <div className="border-shadow p-4 md:w-[90vw] xl:w-[783px] space-y-2">
            <h5 className="text-[#2B8B7A] text-lg font-bold font-opensans">
              Students and Parents
            </h5>
            <p className="text-[#646464] font-light">
              You start the financial aid conversation with the colleges of your
              choice.
            </p>
            <p className="text-[#646464] font-light">
              You tell the schools what you can afford and what it will take for
              you to enroll.
            </p>
            <p className="text-[#646464] font-light">
              You request a quick response and, thus, have plenty of time to...
            </p>
            <ul className="font-opensans font-light text-[#646464] space-y-3">
              <li className="flex space-x-2">
                <img src={check_icon} alt="" />
                <span>Decide to attend or not.</span>
              </li>
              <li className="flex space-x-2">
                <img src={check_icon} alt="" />
                <span>Choose another school if necessary.</span>
              </li>
              <li className="flex space-x-2">
                <img src={check_icon} alt="" />
                <span>More effectively plan how to pay.</span>
              </li>
            </ul>
            <p className="font-light font-opensans text-[#646464] pt-2">
              MyCollegeCosts gives you the detailed data you need to determine
              what you can really afford AND provides you with sample letters
              and emails you can send to the colleges of your choice to ensure
              that you get the dollars you need!
            </p>
          </div>
        </section>
        <section className="space-y-4">
          <CustomTab
            title="Requirements"
            desc="Requirements needed to use MyCollegeCosts."
            onClick={() => {
              if (selectTabSm === 1) setSelectTabSm(0);
              else setSelectTabSm(1);
            }}
            isChecked={selectTabSm === 1}
          />
          {selectTabSm === 1 ? <RequirementBox /> : null}
          <CustomTab
            title="Presenting student aid award"
            desc="Steps needed to present your own student aid award."
            onClick={() => {
              if (selectTabSm === 2) setSelectTabSm(0);
              else setSelectTabSm(2);
            }}
            isChecked={selectTabSm === 2}
          />
          {selectTabSm === 2 ? <PresentingAidAward /> : null}
          <CustomTab
            title="After completing steps"
            desc="What to do after completing your required steps."
            onClick={() => {
              if (selectTabSm === 3) setSelectTabSm(0);
              else setSelectTabSm(3);
            }}
            isChecked={selectTabSm === 3}
          />
          {selectTabSm === 3 ? <AfterCompleting /> : null}
          <CustomTab
            title="About Direct Costs and GAP"
            desc="Learn more about Direct Costs and GAP regarding you."
            onClick={() => {
              if (selectTabSm === 4) setSelectTabSm(0);
              else setSelectTabSm(4);
            }}
            isChecked={selectTabSm === 4}
          />
          {selectTabSm === 4 ? <DirectCostsGAP /> : null}
          <CustomTab
            title="Types of aid"
            desc="Learn more about types of aid and how they differ."
            onClick={() => {
              if (selectTabSm === 5) setSelectTabSm(0);
              else setSelectTabSm(5);
            }}
            isChecked={selectTabSm === 5}
          />
          {selectTabSm === 5 ? <TypesAid /> : null}
          <CustomTab
            title="Money you will not pay back "
            desc="Learn about money you will not have to pay back in regards to your aid. "
            onClick={() => {
              if (selectTabSm === 6) setSelectTabSm(0);
              else setSelectTabSm(6);
            }}
            isChecked={selectTabSm === 6}
          />
          {selectTabSm === 6 ? <MoneyPayback /> : null}

          <CustomTab
            title="Information when You need it"
            desc="Learn more about information that you will need."
            onClick={() => {
              if (selectTabSm === 7) setSelectTabSm(0);
              else setSelectTabSm(7);
            }}
            isChecked={selectTabSm === 7}
          />
          {selectTabSm === 7 ? <InformationYouNeedIt /> : null}

          <CustomTab
            title="Making your case"
            desc="Learn more about making your case when it comes to negotiation."
            onClick={() => {
              if (selectTabSm === 8) setSelectTabSm(0);
              else setSelectTabSm(8);
            }}
            isChecked={selectTabSm === 8}
          />
          {selectTabSm === 8 ? <MakingYourCase /> : null}

          <CustomTab
            title="See results"
            desc="Learn more about the results and information that is unique to you."
            onClick={() => {
              if (selectTabSm === 9) setSelectTabSm(0);
              else setSelectTabSm(9);
            }}
            isChecked={selectTabSm === 9}
          />
          {selectTabSm === 9 ? <SeeResults /> : null}
        </section>
        <section className="bg-[#3FC6AD] -mx-4 p-6">
          <div className="p-8 mx-auto border border-[#3FC6AD] rounded-lg space-y-2 font-opensans bg-white">
            <div>
              <img src={charles_photo} alt="" className="mx-auto" />
            </div>
            <h6 className="text-[#3FC6AD] font-bold text-center">
              College President
            </h6>
            <h6 className="xl:text-xl text-[#2B574F] font-semibold text-center">
              Mr. Charles A. (Tony) McGeorge
            </h6>
            <p className="font-light text-[#2B574F] text-center">
              As the headmaster of three independent secondary schools, I have
              witnessed how the escalating cost of a college education is making
              it increasingly unaffordable for many families. The rising cost of
              college and the looming burden of student debt are causing undue
              financial stress and anxiety for students and their families. My
              commitment to making college affordable for all students and
              MyCollegeCosts' ability to maximize financial aid awards have
              motivated me to join their team.
            </p>
          </div>
        </section>
      </div>
    </>
  );
};
