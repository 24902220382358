import check_icon from "../assets/images/circle_check.svg";
import college_logo from "../assets/images/mycollege_cost_logo.svg";
import ReactPlayer from "react-player/youtube";
import { useState } from "react";
import { CustomTab } from "./StudentParents";
import charles_photo from "../assets/images/charles_photo.png";
import { useNavigate } from "react-router-dom";
type Props = React.HtmlHTMLAttributes<HTMLDivElement> & {
  url: string;
};
export const VideoBox = (props: Props) => {
  const { url, className } = props;
  return (
    <div className={className}>
      <div className={`player-wrapper rounded-lg max-md:hidden`}>
        <ReactPlayer
          url={url}
          width="100%"
          height="100%"
          loop={false}
          controls={true}
          className={"react-player rounded-md"}
        />
      </div>
      <div className={`player-wrapper rounded-lg md:hidden`}>
        <ReactPlayer
          url={url}
          width="100%"
          height={220}
          loop={false}
          controls={true}
          className={"react-player rounded-md"}
        />
      </div>
    </div>
  );
};
type IProps = {
  title: string;
  desc: string;
};
type ItemProps = {
  title: string;
  desc: string;
  src: string;
};
const ItemBox = ({ title, desc }: IProps) => {
  return (
    <div className="flex flex-col space-y-1">
      <h6 className="text-[#2B574F] max-md:text-lg md:text-xl xl:text-2xl font-black flex">
        <img src={check_icon} alt="" />
        {title}
      </h6>
      <p className="max-md:text-sm md:text-base xl:text-lg font-light text-[#646464]">
        {desc}
      </p>
    </div>
  );
};
const ItemSrcBox = ({ src, title, desc }: ItemProps) => {
  return (
    <div>
      <div className="md:w-[416px] grid font-work_sans mx-auto">
        <div className="grid h-[104px]">
          <div>
            <img src={src} alt="" className="mx-auto" />
          </div>
          <h6 className="text-[#2B574F] font-extrabold xl:text-2xl text-center">
            {title}
          </h6>
        </div>
        <p className="font-light text-center">{desc}</p>
      </div>
    </div>
  );
};
const ItemSrcBoxMobile = ({ src, title, desc }: ItemProps) => {
  return (
    <div>
      <div className="md:w-[416px] grid font-work_sans mx-auto">
        <div className="grid h-[104px]">
          <div>
            <img src={src} alt="" className="mx-auto" />
          </div>
          <h6 className="text-[#2B574F] font-extrabold text-xl text-center">
            {title}
          </h6>
        </div>
        <p className="font-light text-center text-[14px]">{desc}</p>
      </div>
    </div>
  );
};

const StudentRequirements = () => {
  const navigate = useNavigate();

  return (
    <div className="font-opensans space-y-3 xl:w-[800px] md:w-[90vw] max-md:border max-md:border-x-0 border-[#DDE0E4] max-md:py-3 px-2">
      <h6 className="text-[#3FC6AD] font-bold md:text-xl text-lg">
        Student requirements
      </h6>
      <h6 className="text-[#646464]">
        In order to use MyCollegeCosts, students must:
      </h6>
      <ul className="space-y-2 font-light list-disc ps-6">
        <li>
          Plan to enroll in a four-year college or university in Fall 2025
        </li>
        <li>Enroll as a Full-Time Freshman or Transfer Student</li>
        <li>Complete and submit their FAFSA</li>
        <li>Be a Dependent Student</li>
        <li>Share selected information from their FAFSA with MyCollegeCosts</li>
        <li>Complete a brief demographic form.</li>
      </ul>
      <div className="flex flex-col items-center md:flex-row max-md:space-y-2 md:space-x-2">
        <h6 className="text-[#2B574F] ">Interested about MyCollegeCosts?</h6>
        <div>
          <button
            className="bg-[#3FC6AD] text-white font-bold py-2 rounded-md w-[140px]"
            onClick={() => {
              navigate("/what_cost");
            }}
          >
            Sign up now
          </button>
        </div>
      </div>
      <p className="italic font-light ">
        The security of your information is important to us!  MyCollegeCosts.ai
        is a web-based secure browser best used on a mobile device.  The
        information provided can only be released by the student to selected
        colleges and universities.
      </p>
    </div>
  );
};
const MyCollegeCostsHelpsStudents = () => {
  const navigate = useNavigate();

  return (
    <div className="font-opensans space-y-3 xl:w-[800px] md:w-[90vw] max-md:border max-md:border-x-0 border-[#DDE0E4] max-md:py-3 px-2">
      <h6 className="text-[#3FC6AD] font-bold md:text-xl text-lg">
        How MyCollegeCosts helps students
      </h6>
      <ul className="space-y-2 font-light list-disc ps-6">
        <li>
          Learn what the direct costs of attending the colleges listed on the
          FAFSA will be.
        </li>
        <li>
          See how much estimated financial aid your students need to pay for
          those direct costs.
        </li>
        <li>
          Understand how much of that financial aid is FREE and how much they
          will need to pay back or pay out-of-pocket.
        </li>
        <li>
          Review the expected GAP between the aid they are likely to be offered
          and how much they need.
        </li>
        <li>
          Engage in the important process of financial planning for college.
        </li>
        <li>Avoid insurmountable debt related to college enrollment.</li>
        <li>
          Receive this information without having to wait for an admission
          decision.
        </li>
        <li>
          Access recommended email and letter text to request the amount of aid
          they need from the colleges they are considering.
        </li>
        <li>
          Know that the college they want to attend is one they can afford to
          attend.
        </li>
      </ul>
      <div className="flex flex-col items-center md:flex-row max-md:space-y-2 md:space-x-2">
        <h6 className="text-[#2B574F] ">Interested about MyCollegeCosts?</h6>
        <div>
          <button
            className="bg-[#3FC6AD] text-white font-bold py-2 rounded-md w-[140px]"
            onClick={() => {
              navigate("/what_cost");
            }}
          >
            Contact us
          </button>
        </div>
      </div>
    </div>
  );
};
const Pricing = () => {
  const navigate = useNavigate();

  return (
    <div className="font-opensans space-y-3 xl:w-[800px] md:w-[90vw] max-md:border max-md:border-x-0 border-[#DDE0E4] max-md:py-3 px-2">
      <h6 className="text-[#3FC6AD] font-bold md:text-xl text-lg">Pricing</h6>
      <p className="font-light">
        Are you concerned that more students have access to the benefits of
        college enrollment?
      </p>
      <p className="font-light">
        Are you searching for a tool that will allow students and families to
        better understand the cost of such an education?
      </p>
      <p className="font-light">
        MyCollegeCosts.ai will allow you to provide the students and families
        you serve answers to their questions about college affordability at no
        charge to the student.  Your foundation will be assessed a charge that
        varies as a function of the number of students you want to invite to be
        part of this process.
      </p>
      <p className="font-light">
        There are no multiple year contracts and the invitation to participate
        comes directly from your foundation. 
      </p>
      <p className="font-light">Want to learn more? </p>
      <p className="font-light">
        Simply complete our contact form and we will be in touch! 
      </p>
      <div className="flex flex-col items-center md:flex-row max-md:space-y-2 md:space-x-2">
        <h6 className="text-[#2B574F] ">Interested about MyCollegeCosts?</h6>
        <div>
          <button
            className="bg-[#3FC6AD] text-white font-bold py-2 rounded-md w-[140px]"
            onClick={() => {
              navigate("/what_cost");
            }}
          >
            Sign up now
          </button>
        </div>
      </div>
    </div>
  );
};
export const Foundations = () => {
  const [selectTabX, setSelectTabX] = useState(1);
  const [selectTabM, setSelectTabM] = useState(0);
  const [selectTabM1, setSelectTabM1] = useState(0);
  const [selectTabSm, setSelectTabSm] = useState(0);
  return (
    <>
      <div className="max-md:hidden grid gap-y-[40px] font-work_sans pb-[40px]">
        <section className="foundation_hero_bg w-100 md:h-[360px] xl:h-[500px] flex items-center justify-center relative">
          <div className="flex justify-center p-8 space-x-4 space-y-2 hero_caption">
            <div className="space-y-3">
              <div className="flex items-center space-x-4">
                <div>
                  <img src={college_logo} alt="" />
                </div>
                <h6 className="text-xl font-extrabold text-white">
                  MyCollegeCosts
                </h6>
              </div>
              <h6 className="text-[#fff] w-[550px] text-center font-poppins font-normal">
                A tool to help students receive the best possible financial aid
                award
              </h6>
            </div>
            {/* <div>
              <button className="text-white bg-[#3FC6AD] font-extrabold px-8 py-3 rounded-lg">
                Register Now
              </button>
            </div> */}
          </div>
        </section>
        <section className="flex xl:justify-center xl:flex-row md:flex-col-reverse xl:space-x-8 md:px-3 font-opensans">
          <div className="flex items-center justify-center">
            <div className="border-shadow md:p-3 xl:p-9 md:w-[90vw] xl:w-[740px] min-h-[448px] space-y-3 text-[#646464]">
              <p className="text-[#2B8B7A] font-bold text-lg">Foundations</p>
              <p className="text-[#646464]">
                It’s time to help students and families take more control of the
                cost of college.
              </p>
              <ul className="font-light list-disc list-inside text-[#646464] space-y-3 ps-4">
                <li>
                  Are you currently working with first-generation college-bound
                  students? 
                </li>
                <li>
                  Working with students for whom college may represent a
                  significant financial burden? 
                </li>
                <li>
                  Want to provide students and families access to better
                  information about the amount they will have to pay to attend a
                  four-year college?
                </li>
                <li>
                  MyCollegeCosts will tell students and their families how much
                  and what type of financial aid they are likely to receive from
                  the colleges they are considering.  We will help them{" "}
                  <strong className="font-bold">
                    understand how much aid they need
                  </strong>{" "}
                  to meet 100% of the direct costs of attending those
                  institutions.
                </li>
                <li>
                  It’s time to help students and families take more control of
                  their college financial future.
                </li>
              </ul>
            </div>
          </div>
          <div className="flex items-center justify-center md:pb-5">
            <VideoBox
              url="https://youtu.be/amrH4IIIlpM"
              className="md:w-[426px] xl:w-[547px] md:h-[272px] xl:h-[350px] rounded-lg"
            />
          </div>
        </section>
        <section>
          <div className="flex justify-between xl:w-[1440px] mx-auto max-xl:hidden">
            <CustomTab
              title="Student requirements"
              desc="Do your students meet these requirements?"
              onClick={() => {
                if (selectTabX === 1) setSelectTabX(0);
                else setSelectTabX(1);
              }}
              isChecked={selectTabX === 1}
            />
            <CustomTab
              title="How MyCollegeCosts helps students"
              desc="Learn about how MyCollegeCosts can help your students."
              onClick={() => {
                if (selectTabX === 2) setSelectTabX(0);
                else setSelectTabX(2);
              }}
              isChecked={selectTabX === 2}
            />
            <CustomTab
              title="Pricing"
              desc="Learn about the pricing when it comes to MyCollegeCosts."
              onClick={() => {
                if (selectTabX === 3) setSelectTabX(0);
                else setSelectTabX(3);
              }}
              isChecked={selectTabX === 3}
            />
          </div>
          {selectTabX === 0 ? null : (
            <div className="xl:w-[1440px] mx-auto border border-x-0 border-y-1 border-[#DDE0E4] py-4 mt-4 max-xl:hidden">
              {selectTabX === 1 ? <StudentRequirements /> : null}
              {selectTabX === 2 ? <MyCollegeCostsHelpsStudents /> : null}
              {selectTabX === 3 ? <Pricing /> : null}
            </div>
          )}

          <div className="max-md:hidden md:flex justify-between xl:hidden md:w-[90vw] mx-auto space-x-3">
            <CustomTab
              title="Student requirements"
              desc="Do your students meet these requirements?"
              onClick={() => {
                if (selectTabM === 1) setSelectTabM(0);
                else setSelectTabM(1);
              }}
              isChecked={selectTabM === 1}
            />
            <CustomTab
              title="How MyCollegeCosts helps students"
              desc="Learn about how MyCollegeCosts can help your students."
              onClick={() => {
                if (selectTabM === 2) setSelectTabM(0);
                else setSelectTabM(2);
              }}
              isChecked={selectTabM === 2}
            />
          </div>
          {selectTabM === 0 ? null : (
            <div className="md:w-[90vw] mx-auto border border-x-0 border-y-1 border-[#DDE0E4] py-4 mt-4 xl:hidden max-md:hidden">
              {selectTabM === 1 ? <StudentRequirements /> : null}
              {selectTabM === 2 ? <MyCollegeCostsHelpsStudents /> : null}
            </div>
          )}

          <div className="max-md:hidden md:flex justify-between xl:hidden md:w-[90vw] mx-auto space-x-3">
            <CustomTab
              title="Pricing"
              desc="Learn about the pricing when it comes to MyCollegeCosts."
              onClick={() => {
                if (selectTabM1 === 1) setSelectTabM1(0);
                else setSelectTabM1(1);
              }}
              isChecked={true}
            />
          </div>
          {selectTabM1 === 0 ? null : (
            <div className="md:w-[90vw] mx-auto border border-x-0 border-y-1 border-[#DDE0E4] py-4 mt-4 xl:hidden max-md:hidden">
              <Pricing />
            </div>
          )}
        </section>
        <section>
          <div className="md:w-[85vw] xl:w-[916px] p-8 mx-auto border border-[#3FC6AD] rounded-lg space-y-2 font-opensans">
            <div>
              <img src={charles_photo} alt="" className="mx-auto" />
            </div>
            <h6 className="text-[#3FC6AD] font-bold text-center">
              College President
            </h6>
            <h6 className="xl:text-xl text-[#2B574F] font-semibold text-center">
              Mr. Charles A. (Tony) McGeorge
            </h6>
            <p className="font-light text-[#2B574F] text-center">
              I joined MyCollegeCosts because, as the past president of a charitable foundation providing 
              college scholarships to young men and women, I am acutely aware of the rising cost of tuition. 
              College tuition at public four-year institutions increased by 36.7% from 2010 to 2023, and after 
              accounting for inflation, it increased by 197.4%. MyCollegeCosts aims to maximize your financial 
              aid award and make college accessible to all young men and women.
            </p>
          </div>
        </section>
      </div>
      <div className="grid px-4 py-4 md:hidden gap-y-4 font-work_sans">
        <section className="flex flex-col justify-center space-y-3">
          <div className="flex items-center">
            <VideoBox
              url="https://youtu.be/amrH4IIIlpM"
              className="w-full rounded-lg"
            />
          </div>
          <div className="flex items-center justify-center">
            <div className="border-shadow p-3 xl:p-9 md:w-[90vw] xl:w-[740px] min-h-[448px] space-y-3 text-[#646464]">
              <p className="text-[#3FC6AD] font-bold text-lg">Foundations</p>
              <p className="text-[#646464]">
                It’s time to help students and families take more control of the
                cost of college.
              </p>
              <ul className="font-light list-disc list-inside list-outside text-[#646464] space-y-3 ps-4">
                <li>
                  Are you currently working with first-generation college-bound
                  students? 
                </li>
                <li>
                  Working with students for whom college may represent a
                  significant financial burden? 
                </li>
                <li>
                  Want to provide students and families access to better
                  information about the amount they will have to pay to attend a
                  four-year college?
                </li>
                <li>
                  MyCollegeCosts will tell students and their families how much
                  and what type of financial aid they are likely to receive from
                  the colleges they are considering.  We will help them{" "}
                  <strong className="font-bold">
                    understand how much aid they need
                  </strong>{" "}
                  to meet 100% of the direct costs of attending those
                  institutions.
                </li>
                <li>
                  It’s time to help students and families take more control of
                  their college financial future.
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="space-y-4">
          <CustomTab
            title="Student requirements"
            desc="Do your students meet these requirements?"
            onClick={() => {
              if (selectTabSm === 1) setSelectTabSm(0);
              else setSelectTabSm(1);
            }}
            isChecked={selectTabSm === 1}
          />
          {selectTabSm === 1 ? <StudentRequirements /> : null}
          <CustomTab
            title="How MyCollegeCosts helps students"
            desc="Learn about how MyCollegeCosts can help your students."
            onClick={() => {
              if (selectTabSm === 2) setSelectTabSm(0);
              else setSelectTabSm(2);
            }}
            isChecked={selectTabSm === 2}
          />
          {selectTabSm === 2 ? <MyCollegeCostsHelpsStudents /> : null}
          <CustomTab
            title="Pricing"
            desc="Learn about the pricing when it comes to MyCollegeCosts."
            onClick={() => {
              if (selectTabSm === 3) setSelectTabSm(0);
              else setSelectTabSm(3);
            }}
            isChecked={selectTabSm === 3}
          />
          {selectTabSm === 3 ? <Pricing /> : null}
        </section>
        <section className="bg-[#3FC6AD] -mx-4 p-6">
          <div className="p-8 mx-auto border border-[#3FC6AD] rounded-lg space-y-2 font-opensans bg-white">
            <div>
              <img src={charles_photo} alt="" className="mx-auto" />
            </div>
            <h6 className="text-[#3FC6AD] font-bold text-center">
              College President
            </h6>
            <h6 className="xl:text-xl text-[#2B574F] font-semibold text-center">
              Mr. Charles A. (Tony) McGeorge
            </h6>
            <p className="font-light text-[#2B574F] text-center">
              As the headmaster of three independent secondary schools, I have
              witnessed how the escalating cost of a college education is making
              it increasingly unaffordable for many families. The rising cost of
              college and the looming burden of student debt are causing undue
              financial stress and anxiety for students and their families. My
              commitment to making college affordable for all students and
              MyCollegeCosts' ability to maximize financial aid awards have
              motivated me to join their team.
            </p>
          </div>
        </section>
      </div>
    </>
  );
};
