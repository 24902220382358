import { useForm } from "react-hook-form";
import { useState } from "react";
import bill_photo from "../assets/images/bill_photo.png";
import colleen_photo from "../assets/images/colleen_photo.png";
import charles_photo from "../assets/images/charles_pr_photo.png";
import pam_photo from "../assets/images/pam_photo.png";
export const backend_baseUrl = process.env.REACT_APP_BACKEND_URL;
type ContactFrm = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  userType: string;
  message: string;
  permission: boolean;
};
type IProfileType = {
  name: string;
  link: string;
  description: string;
  url: string;
};
const Profile = ({ link, name, description, url }: IProfileType) => {
  return (
    <div className="xl:w-[432px] md:w-[330px] w-[90vw] shadow-lg rounded-lg mb-6 max-md:mx-auto border border-[#3FC6AD]">
      <div className=" rounded-t-lg">
        <img
          src={link}
          alt=""
          className="rounded-t-lg xl:w-[432px] xl:h-[208px]"
        />
      </div>
      <div className="p-4 grid gap-y-3 xl:min-h-[247px] place-content-between">
        <div className=" text-[#2B574F] font-bold">{name}</div>
        <p className="text-[#646464] font-light xl:min-h-[250px] md:min-h-[325px]">{description}</p>
        <div className="text-[#3FC6AD] ">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => {
              window.open(url, "_blank");
            }}
            className="cursor-pointer"
          >
            <path
              d="M9.91667 17.25H6.25V6.25H9.91667V8.08333C10.6982 7.08898 11.8835 6.49619 13.1479 6.46725C15.4218 6.47987 17.2566 8.33027 17.25 10.6042V17.25H13.5833V11.0625C13.4367 10.0382 12.5583 9.27828 11.5236 9.2805C11.071 9.29481 10.6438 9.49296 10.3406 9.82924C10.0373 10.1655 9.88426 10.6109 9.91667 11.0625V17.25ZM4.41667 17.25H0.75V6.25H4.41667V17.25ZM2.58333 4.41667C1.57081 4.41667 0.75 3.59586 0.75 2.58333C0.75 1.57081 1.57081 0.75 2.58333 0.75C3.59586 0.75 4.41667 1.57081 4.41667 2.58333C4.41667 3.06956 4.22351 3.53588 3.8797 3.8797C3.53588 4.22351 3.06956 4.41667 2.58333 4.41667Z"
              fill="#3FC6AD"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
export const AboutUs = () => {
  const {
    register,
    getValues,
    formState: { isValid },
  } = useForm<ContactFrm>();
  const [isLoading, setIsLoading] = useState(false);
  const userKinds = [
    "Student",
    "Parent",
    "Guidance Counselor",
    "College or University",
  ];
  return (
    <div className="flex flex-col items-center font-opensans min-h-wrap mt-8">
      <div className="my-auto xl:w-[1360px] space-y-4 md:px-8 xl:px-0 space-y-8">
        <section className=" space-y-3 max-md:px-4">
          <h6 className="text-xl font-semibold text-[#2B574F]">Our team</h6>
          <h5 className="text-2xl font-extrabold text-[#3FC6AD]">
            A Better Way to Understand College Cost
          </h5>
          <p className="text-[#646464] font-light">
            MyCollegeCosts has been developed by a team of higher education
            financial aid, enrollment management, and technology experts.
            Collectively, these professionals have more than 100 years of
            experience in these fields.
            <br />
            <br />
            Our professionals have worked with thousands of high school seniors
            who have negotiated the often-confusing process of applying for
            financial aid AND paying for their college education. Those
            experiences have underscored the importance of providing all FAFSA
            submitters and their and families estimated cost information EARLY
            in the college decision making process. The many students who
            discover that their award awards do not meet their financial need
            often do not realize that certain types of aid are negotiable. These
            families need not accept debt they are unable to support. We are
            committed to helping students realize their educational goals
            without incurring insurmountable debt. We help both students and
            families recognize that the initial financial aid award a student is
            offered will impact the financial aid award that student receives in
            subsequent years of enrollment.
          </p>
        </section>
        <section className="md:flex justify-between max-md:space-y-6 flex-wrap">
          <Profile
            link={bill_photo}
            name="Bill Miller"
            description="Bill Miller is the Founder of My College Costs, LLC and helped to oversee the development of a new AI based student aid tool. MyCollegeCosts will allow a student to calculate and present their own self-generated student aid package. This estimated package can be sent to colleges the student is considering independent of the student’s admission status or the institution’s packaging timeline making cost to attend information transparent early in the college choice process."
            url="https://www.linkedin.com/in/bill-miller24/"
          />
          <Profile
            link={colleen_photo}
            name="Colleen Bielitz"
            description="Colleen Bielitz, PhD the co-founder of My College Costs, LLC is an accomplished academic, administrator, entrepreneur, tech futurist and biomimicry proponent. For the past twenty five years she has been transforming institutions through strategic initiatives and visionary leadership by delivering pragmatic ideas and insights into the trends, technologies, and paradigms transforming education and society. Her driving force, to create a world that is sustainable and leaves no one behind."
            url="https://www.linkedin.com/in/colleen-bielitz-phd/"
          />
          <Profile
            link={charles_photo}
            name="Tony McGeorge"
            description="Tony McGeorge brings distinguished professional experience in non-profit and academic sectors to MyCollege Costs. He is the past president of Mustang Scholars’ Foundation, Inc., President Emeritus of the
            Missouri Military Academy, Past President of Valley Forge Military Academy & College, and Head of
            School at The Phelps School. He is the former president of the Association of Military Colleges and
            Schools of the U. S. and served on the Board of Directors of the Pennsylvania Association of Independent
            Schools."
            url="https://www.linkedin.com/in/charles-a-mcgeorge-b0849118/"
          />
          <Profile
            link={pam_photo}
            name="Pam Cabalka"
            description="Pam Cabalka is an experienced Marketing, Sales and Operations Executive who works with both established and start-up technology, higher education and other innovative organizations to evaluate potential markets for best short and long-term ROI, build growth-oriented business plans and assist in driving successful execution. A strategic planning expert, Pam brings both quantitative and qualitative skills and experience together to ensure sales and marketing alignment and drive corporate growth. "
            url="https://www.linkedin.com/in/pamschapercabalka/"
          />
        </section>
      </div>
    </div>
  );
};
